import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Hello World",
  "date": "2015-05-01T22:12:03.284Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is my first post on my new fake blog! How exciting!`}</p>
    <p>{`I'm sure I'll write a lot more interesting things in the future.`}</p>
    <p>{`Oh, and here's a great quote from this Wikipedia on
`}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Salted_duck_egg"
      }}>{`salted duck eggs`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`A salted duck egg is a Chinese preserved food product made by soaking duck
eggs in brine, or packing each egg in damp, salted charcoal. In Asian
supermarkets, these eggs are sometimes sold covered in a thick layer of salted
charcoal paste. The eggs may also be sold with the salted paste removed,
wrapped in plastic, and vacuum packed. From the salt curing process, the
salted duck eggs have a briny aroma, a gelatin-like egg white and a
firm-textured, round yolk that is bright orange-red in color.`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "./salty_egg.jpg",
        "alt": "Chinese Salty Egg"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      